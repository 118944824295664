import { useUserContext } from 'context/userContext';
import { SelectProps } from '../types';
import { update } from 'firebase/database';
import { useRefHelper } from 'hooks/useRefConsumer';

const SelectButton = ({
  body,
  onClick,
  answerId,
  uuid,
  secondSetQuestionId,
  styles
}: SelectProps) => {
  const { dbReferences } = useRefHelper(uuid);
  const { messages } = useUserContext();
  const [, messagesRef] = dbReferences;

  const handleClick = () => {
    const noloResponses = messages?.noloResponses ? messages.noloResponses : [];
    if (Object.keys(noloResponses).length > 0 && secondSetQuestionId) {
      const updateNoloResponse = (noloResponses[secondSetQuestionId - 1] =
        answerId as number);
      update(messagesRef, { NoloResponses: updateNoloResponse });
    }

    onClick(body);
    const dropDown = document.getElementById('dropdown-basic-button');
    // CIRCLEBACK: It was passing a callback to click() but click() doesn't take any parameters/callback
    // document.getElementById('dropdown-basic-button')?.click((e) => {
    //   e.toElement.parentElement.click();
    // });
    // I think this is what is trying to be done, but i am not sure... need to come back to this
    dropDown?.addEventListener('click', (mouseEvent: MouseEvent) => {
      mouseEvent.stopPropagation();
      dropDown?.parentElement?.click();
    });

    if (dropDown?.click) {
      dropDown.click();
    }
  };
  return (
    <button
      className={`${styles} leading-4 m-1.25 py-1.25 px-2.5 bg-white text-black font-bold block border-none text-left w-full`}
      onClick={handleClick}
    >
      {body}
    </button>
  );
};

export default SelectButton;
