import type { AllMessages } from '../types';
import storeMessage from './storeMessage';
import { DatabaseReference } from 'firebase/database';

const sendMessage = (
  message: AllMessages,
  newMessage: AllMessages,
  setInputToInvisible: () => void,
  setNewPlaceholderText: (placeholderText: string) => void,
  messagesRef: DatabaseReference,
  messageLength: number,
  timeout = 3000
) => {
  setTimeout(() => {
    setNewPlaceholderText('');
    setInputToInvisible();
  }, timeout);

  storeMessage(messagesRef, {
    [messageLength]: message
  } as AllMessages);
  if (newMessage) {
    storeMessage(messagesRef, {
      [messageLength + 1]: newMessage
    } as AllMessages);
  }
};

export default sendMessage;
