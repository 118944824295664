import { DatabaseReference, update } from 'firebase/database';
import { changeTimezone } from './message_helper';

import { prodSetup } from '../constants';

const initialParameters = (
  InitialMessages: any[],
  conversationRef: DatabaseReference,
  messagesRef: DatabaseReference,
  userRef: DatabaseReference
) => {
  const current = new Date();
  const today = changeTimezone(current, 'America/New_York');
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  const url =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  const initialUserUpdates = {
    Date: date,
    Time: time,
    VisitToken: prodSetup.visitToken
  };

  const initialConvoUpdates = {
    PostSent: false,
    ChatOver: false,
    CurrentQuestionSet: 'initial-questions',
    CurrentPage: url,
    Source: 'LAWFTY',
    Brand: prodSetup.brand
  };

  // const newUserKey = push(child(dbRef, `${uuid}`)).key;
  update(userRef, initialUserUpdates);
  update(conversationRef, initialConvoUpdates);
  update(messagesRef, { ...InitialMessages });
};

export default initialParameters;
