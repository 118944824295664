import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from 'lawfty_messages.json';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { update } from 'firebase/database';
import { useRefHelper } from 'hooks/useRefConsumer';
import getNextMessage from 'helpers/getNextMessage';
import { useInput, usePlaceholder } from 'hooks/useStateReducer';
import type { AllMessages, Conversation, NameProps, User } from '../types';
import { useUserContext } from 'context/userContext';

const Name = ({ disabled, language, uuid, messageLength }: NameProps) => {
  const [isDisabled, setDisabled] = useState(disabled);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { conversation, user } = useUserContext();
  const { setNewPlaceholderText } = usePlaceholder();
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { dbReferences } = useRefHelper(uuid);
  const [, , userRef] = dbReferences;

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isDisabled) {
      const form = e.target as HTMLFormElement;
      const input = form?.firstChild as HTMLInputElement;
      const value = input.value;

      setDisabled(true);
      if (value) {
        const [first, last] = value.split(' ');

        update(userRef, { Name: value });
        update(userRef, { FirstName: first || '', LastName: last || '' });
        return getNextMessage(
          {
            author: 'User',
            me: false,
            body: value
          } as AllMessages,
          dbReferences,
          setInputToInvisible,
          setInputToVisible,
          setNewPlaceholderText,
          value,
          messageLength,
          conversation as Conversation,
          user as User
        );
      }
    }
  };

  if (!isDisabled) inputRef?.current?.focus();

  return (
    <div>
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        style={{ display: 'inline-flex', width: '100%' }}
      >
        <input
          className={`${
            isDisabled
              ? 'border-b-2 border-b-solid border-b-darkgray'
              : 'border-none bg-valid'
          } py-1.25 px-0.75 w-full text-base focus:outline-none focus:bg-valid touch-none`}
          id="name-input"
          ref={inputRef}
          type="text"
          placeholder={lawftyData.NamePlaceholder[language]}
          disabled={isDisabled}
          onChange={(e) => {
            if (e.target.value !== '' && buttonDisabled)
              setButtonDisabled(false);
          }}
        />
        <button
          type="submit"
          className="bg-send border-none text-white font-bold h-send w-send"
          disabled={buttonDisabled}
        >
          <FontAwesomeIcon id="arrow-right" icon="arrow-right" />
        </button>
      </form>
    </div>
  );
};

export default Name;
