// /// <reference types="@welldone-software/why-did-you-render" />
// // import useCloseChatIdleTimer from 'hooks/useCloseChatIdleTimer';
// import React from 'react';
// import { useUuid } from 'hooks/useStateReducer';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackExtraHooks: [
//       [useUuid, 'useUuid'],
//       [useCloseChatIdleTimer, 'useIdleTimer']
//     ]
//   });
// }
