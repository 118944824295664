import type {
  AllMessages,
  Conversation,
  MessageFormProps,
  User
} from '../../../types';
import { CSSTransition } from 'react-transition-group';
import { FormEvent, useEffect, useRef, KeyboardEvent } from 'react';
import lawftyData from 'lawfty_messages.json';
import './transitions.css';

import getNextMessage from 'helpers/getNextMessage';
import { useInput, useMessage, usePlaceholder } from 'hooks/useStateReducer';
import { useUserContext } from 'context/userContext';

const MessageForm = ({
  disabled,
  language,
  uuid,
  dbReferences
}: MessageFormProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { messages, conversation, user } = useUserContext();
  const { resetMessages } = useMessage(uuid);
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { setNewPlaceholderText } = usePlaceholder();

  useEffect(() => {
    if (textAreaRef.current && !disabled) {
      textAreaRef.current.focus();
    }

    setTimeout(() => {
      document.querySelector('.MessageList')?.scroll({
        top: document.querySelector('.MessageList')?.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 500);
  }, []);

  const handleOnKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      const form = document.getElementById(
        'message-send-btn'
      ) as HTMLButtonElement;
      form?.click();
      const input = form?.firstChild as HTMLTextAreaElement;
      input.value = '';
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.target as HTMLFormElement;
    const input = form?.firstChild as HTMLTextAreaElement;
    const value = input.value;

    if (value.length == 0) {
      return
    }

    resetMessages();
    input.value = '';
    const messageLength = Object.entries(messages as AllMessages).length;
    getNextMessage(
      {
        author: 'User',
        me: false,
        body: value
      } as AllMessages,
      dbReferences,
      setInputToInvisible,
      setInputToVisible,
      setNewPlaceholderText,
      textAreaRef.current?.value as string,
      messageLength,
      conversation as Conversation,
      user as User
    );
  };

  return (
    // TODO: Transition Styles
    <CSSTransition
      classNames="MessageForm"
      in={true}
      timeout={1200}
      appear={true}
      enter={true}
      exit={true}
    >
      <form
        id="messageForm"
        className="basis-[70px] flex-shrink-0 rounded-t-none rounded-b flex bg-eee border-0 p-1.25 text-black text-base box-border border-t border-t-solid border-t-ccc"
        onSubmit={handleFormSubmit}
      >
        <textarea
          className="pl-1 w-full h-full bg-white box-border text-black border border-solid border-ccc outline-none touch-none text-2xl"
          placeholder={lawftyData.TextInput[language || 'english']}
          disabled={disabled}
          ref={textAreaRef}
          form="messageForm"
          onKeyDown={(e) => handleOnKeyDown(e)}
        ></textarea>
        <button
          type="submit"
          id="message-send-btn"
          className="bg-send text-white font-bold text-2xl border-2 border-send-border w-20 ml-1.5 mr-1.5 h-full border-box outline-none"
        >
          {lawftyData.SendMessage[language || 'english']}
        </button>
      </form>
    </CSSTransition>
  );
};

export default MessageForm;
