import { createContext, useContext, ReactNode, useEffect } from 'react';
import { useMessage, useUuid } from '../hooks/useStateReducer';
import { UserContext } from 'types';
import { onValue } from 'firebase/database';
import { useRefHelper } from '../hooks/useRefConsumer';

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const { uuid } = useUuid();
  const { messages, conversation, user, restoredMessages } = useMessage(uuid);
  const { dbReferences } = useRefHelper(uuid);
  const [, , userRef] = dbReferences;

  useEffect(() => {
    onValue(userRef, () => {
      restoredMessages();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageContext.Provider
      value={{
        messages: messages,
        conversation: conversation,
        user: user
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

const MessageContext = createContext<UserContext>({
  messages: undefined,
  conversation: undefined,
  user: undefined
});

export const useUserContext = () => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }
  return context;
};
