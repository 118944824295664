// Library Imports
import { useEffect } from 'react';
import { withIdleTimer } from 'react-idle-timer';

import { AppInfo } from './constants';

// App Context, Helper, Hooks, and State
import { FirebaseProvider, MessageProvider } from 'context';
import useCloseChatIdleTimer from 'hooks/useCloseChatIdleTimer';
import { useMessage, useUuid } from 'hooks/useStateReducer';
import type { AllMessages } from 'types';
import { useRefHelper } from 'hooks/useRefConsumer';

// App Components
import Disclaimer from 'components/Disclaimer';
import FirmLogo from 'components/FirmLogo';
import MessageList from 'components/Messages/MessageList';
import SecurityNotification from 'components/SecurityNotification';

// Assets
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faLock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import initialParameters from 'helpers/onSetup';
import { InitialMessages } from 'constants/index';

library.add(faLock);
library.add(faTimesCircle);
library.add(faArrowRight);

const { language, campaignId, brand } = AppInfo;

const App = () => {
  const { uuid } = useUuid();
  const { dbReferences } = useRefHelper(uuid);
  const { messages } = useMessage(uuid);

  const [conversationRef, messagesRef, userRef] = dbReferences;

  useCloseChatIdleTimer(messages as AllMessages, language, uuid);

  useEffect(() => {
    initialParameters(InitialMessages, conversationRef, messagesRef, userRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FirebaseProvider>
      <MessageProvider>
        <div
          className={`wrapper ${
            brand === 'd2c' ? 'bg-d2c' : 'bg-white'
          } flex flex-col box-border absolute top-0 right-0 z-50 rounded text-[13px] leading-6 text-[#333] h-full w-full`}
        >
          <FirmLogo campaignId={campaignId} brand={brand} />
          <SecurityNotification language={language} brand={brand} />
          <MessageList uuid={uuid} brand={brand} />
        </div>
        <Disclaimer language={language} />
      </MessageProvider>
    </FirebaseProvider>
  );
};

export default withIdleTimer(App);
