import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from 'lawfty_messages.json';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { update } from 'firebase/database';
import { useRefHelper } from 'hooks/useRefConsumer';
import getNextMessage from 'helpers/getNextMessage';
import { useInput, usePlaceholder } from 'hooks/useStateReducer';
import type { AllMessages, Conversation, User, ZipCodeProps } from '../types';
import { useUserContext } from 'context/userContext';

const ZipCode = ({ uuid, language, disabled, messageLength }: ZipCodeProps) => {
  // disabled state isn't used anywhere anymore. needs to be removed everywhere.
  const [isDisabled, setDisabled] = useState(disabled || false);
  const [zipcode, setZipcode] = useState<string>('');
  const [backspace, setBackspace] = useState<boolean>(false);
  const [style] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { conversation, user } = useUserContext();
  const { setNewPlaceholderText } = usePlaceholder();
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { dbReferences } = useRefHelper(uuid);
  const [, , userRef] = dbReferences;

  const handleValidation = (e: ChangeEvent<HTMLInputElement>) => {
    const validation = /[0-9]/g;
    e.target.onkeydown = (e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        return setBackspace(true);
      } else {
        return setBackspace(false);
      }
    };

    if ((zipcode.length < 5 && e.target.value.match(validation)) || backspace) {
      setZipcode(e.target.value);
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form?.firstChild as HTMLInputElement;
    const value = input.value;

    if (!isDisabled && zipcode.length === 5) {
      setDisabled(true);
      update(userRef, { Address: value });
      return getNextMessage(
        {
          author: 'User',
          me: false,
          body: value
        } as AllMessages,
        dbReferences,
        setInputToInvisible,
        setInputToVisible,
        setNewPlaceholderText,
        value,
        messageLength,
        conversation as Conversation,
        user as User
      );
    }
  };

  if (!isDisabled) inputRef?.current?.focus();

  return (
    <div>
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        style={{ display: 'inline-flex', width: '100%' }}
      >
        <input
          className={`w-full pt-4 pr-4 pb-4 pl-1 border-none text-base focus:outline-none focus:bg-email-input touch-none ${style}`}
          id="zip-code-input"
          ref={inputRef}
          type="text"
          placeholder={lawftyData.ZipCodePlaceholder[language]}
          disabled={isDisabled}
          onChange={(e) => handleValidation(e)}
          value={zipcode}
          style={
            zipcode?.length === 5
              ? { borderBottom: '2px solid green', backgroundColor: '#f2f8f2' }
              : {}
          }
        />
        <button
          className="bg-send border-none text-white font-bold h-send w-send"
          id="zip-send-button"
          type="submit"
        >
          <FontAwesomeIcon id="arrow-right" icon="arrow-right" />
        </button>
      </form>
    </div>
  );
};

export default ZipCode;
