import { useState } from 'react';
import {
  type DatabaseReference,
  type DataSnapshot,
  get,
  ref as dataRef,
  Database,
  getDatabase
} from 'firebase/database';
import { firebase, useFirebaseContext } from '../context/index';
import { AppInfo } from '../constants';
import lawftyData from '../lawfty_messages.json';

export type RefObject<T> = {
  [Property in keyof T as string]: T[Property];
};

export const RootRef = {
  conversations: 'conversations',
  experiments: 'experiments',
  posts: 'posts',
  users: 'users'
} as RefObject<JSON>;

export const useRefHelper = (uuid: string) => {
  const { getRef } = useRefConsumer();
  const [secondCaseTypes, setSecondSetCaseTypes] = useState(
    // @ts-ignore
    lawftyData.LawyerCaseTypes[AppInfo.campaignType][AppInfo.language].sort()
  );

  const userRef = getRef(uuid);
  const conversationRef = getRef(uuid + '/conversation');
  const messagesRef = getRef(uuid + '/conversation/messages');
  const questionsLeft = getRef(uuid + '/conversation/QuestionsLeft');

  const dbReferences: DatabaseReference[] = [
    conversationRef,
    messagesRef,
    userRef,
    questionsLeft
  ];

  return {
    dbReferences,
    secondCaseTypes,
    setSecondSetCaseTypes
  };
};

export const useRefConsumer = () => {
  //const { firebaseDb } = useFirebaseContext();
  const firebaseDb: Database = getDatabase(firebase);

  // Returns DataSnapshot object so that we can access values
  const getSnapshotByRef = async (
    snapshot: DatabaseReference
  ): Promise<DataSnapshot> => {
    if (snapshot) {
      try {
        await get(snapshot);
      } catch (e) {
        throw new Error(
          `The database reference, ${snapshot}, you are trying to fetch could not be reached. Error: ${e}`
        );
      }
      return await get(snapshot);
    } else {
      if (snapshot) {
        try {
          await get(snapshot);
        } catch (e) {
          throw new Error(
            `The database reference, ${snapshot}, you are trying to fetch doesnnot match any keys in GetRef. Error: ${e}`
          );
        }
      }

      return await get(snapshot);
    }
  };

  // TODO: Make RootRef dictionary for all endpoints
  const getRef = (refToGrab: string | undefined): DatabaseReference => {
    if (refToGrab && RootRef[refToGrab]) {
      try {
        dataRef(firebaseDb, refToGrab);
      } catch (e) {
        throw new Error(
          `The database reference, ${refToGrab}, you are trying to fetch could not be reached. Error: ${e}`
        );
      }
      return dataRef(firebaseDb, refToGrab);
    } else {
      if (refToGrab !== '') {
        try {
          dataRef(firebaseDb, refToGrab);
        } catch (e) {
          throw new Error(
            `The database reference, ${refToGrab}, you are trying to fetch does not match any keys in GetRef. Error: ${e}`
          );
        }
      } else {
        try {
          dataRef(firebaseDb);
        } catch (e) {
          throw new Error(
            `The database reference for the root database could not be found. Error: ${e}`
          );
        }
      }
    }
    return dataRef(firebaseDb, refToGrab);
  };

  return { getRef, getSnapshotByRef };
};
