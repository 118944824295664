import { DatabaseReference, update } from 'firebase/database';
import type { AllMessages } from '../types';

const storeMessage = (
  messageRef: DatabaseReference,
  addMessage: AllMessages
) => {
  update(messageRef, addMessage)
    .then(() => {
      console.log(`Database has been updated successfully at: ${messageRef}`);
    })
    .catch((e) => {
      throw new Error(`Database did not update correctly: ${e}`);
    });
};

export default storeMessage;
