import { createContext, useContext, ReactNode } from 'react';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getDatabase, type Database } from 'firebase/database';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const firebaseApp = (firebaseConfig: FirebaseOptions) =>
  initializeApp(firebaseConfig);
interface FirebaseAppContext {
  firebase: FirebaseApp;
  firebaseDb: Database;
}

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
  return (
    <FirebaseContext.Provider
      value={{
        firebase: firebase,
        firebaseDb: firebaseDb
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const firebase: FirebaseApp = firebaseApp(firebaseConfig);
const firebaseDb: Database = getDatabase(firebase);
const FirebaseContext = createContext<FirebaseAppContext>({
  firebase: firebase,
  firebaseDb: firebaseDb
});

export const useFirebaseContext = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseContext must be used within a FirebaseProvider'
    );
  }
  return context;
};

export { MessageProvider } from './userContext';
