import { DatabaseReference, update } from 'firebase/database';
import sendMessage from './sendMessage';

import secondSetNolo from 'nolo_questions.json';
import secondSetQuestions from 'lawyer_questions.json';
import lawftyData from 'lawfty_messages.json';
import { AgentName, AppInfo } from '../constants';
import { closeChat } from './message_helper';
import type {
  AllMessages,
  Button,
  CaseType,
  Conversation,
  OtherSeq,
  User
} from 'types';

const getNextMessage = (
  messages: AllMessages,
  dbReferences: DatabaseReference[],
  setInputToInvisible: () => void,
  setInputToVisible: () => void,
  setNewPlaceholderText: (placeholder: string) => void,
  text: string,
  messageLength: number,
  conversation: Conversation,
  user: User
) => {
  const { language, inputFieldsSequence, campaignType, brand } = AppInfo;
  const [conversationRef, messagesRef, userRef] = dbReferences;
  const secondSetCaseTypes: CaseType[] = [
    ...lawftyData.LawyerCaseTypes[campaignType][language].sort(),
    { answer: 1, answer_text: 'NO' }
  ];

  let newBotMessage = {
    author: AgentName.Patricia,
    me: false,
    body: '',
    buttons: [] as Button[],
    options: [] as string[],
    secondSetQuestionId: 0,
    disabled: false,
    language: language,
    input: '',
    brand: brand,
    inputType: '',
    inputId: ''
  };

  // Add back in Bankruptcy nolo flow
  // Other - No flow needs unique flow asking for details

  if (text === 'NO') {
    // if they don't select any case types
    const url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;
    update(conversationRef, { CurrentPage: url });

    const otherSeq: OtherSeq[] = conversation.QuestionsLeft
      ? Object.values(conversation.QuestionsLeft)
      : [...lawftyData.OtherSequence[language]];

    newBotMessage.body = otherSeq[0].prompt;
    newBotMessage.input = 'Input';
    newBotMessage.inputType = 'text';
    newBotMessage.inputId = 'AdditionalDetails';
    const inputFields = ['PhoneNumber', ...inputFieldsSequence];
    inputFields[2] = 'Details';
    update(conversationRef, {
      CurrentQuestionSet: 'input-fields',
      InputFieldsLeft: inputFields,
      CaseType: text,
      SendToNolo: false
    });
  } else if (text === 'OTHER' || text === 'OTRO') {
    //if they hit other (in the first question) then get the second set case types
    newBotMessage.body = lawftyData.MoreOptionsPrompt[language];
    const caseTypes = secondSetCaseTypes;
    newBotMessage.buttons = caseTypes;
    update(conversationRef, { CaseType: text });
  } else if (conversation?.CaseType === 'OTHER') {
    //if they clicked on a second set case type, get the questions that apply

    const url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    setInputToInvisible();
    const inputFields = [...inputFieldsSequence];
    const input = inputFields[0];
    newBotMessage.input = input;
    if (input === 'PhoneNumber') {
      newBotMessage.body = lawftyData?.[language][text][0]?.prompt;
    } else if (input === 'Info') {
      newBotMessage.inputType = 'text';
      newBotMessage.body =
        lawftyData?.[language][conversation.PracticeAreaId][1]?.prompt;
      newBotMessage.inputId = 'AdditionalDetails';
    } else {
      newBotMessage.body = lawftyData?.[input][language];
    }

    update(conversationRef, {
      LawftyCase: false,
      CaseType: lawftyData.CaseTypeMap[text],
      PracticeAreaId: text,
      CurrentQuestionSet: 'input-fields',
      InputFieldsLeft: inputFields,
      CurrentPage: url,
      SendToNolo: true
    });
  } else if (
    conversation?.PracticeAreaId &&
    conversation?.CurrentQuestionSet === 'final-questions' &&
    conversation?.InputFieldsLeft === undefined
  ) {
    const secondSetData =
      conversation.PracticeAreaId === 'BANCARROTA' ||
      conversation.PracticeAreaId === 'BANKRUPTCY'
        ? secondSetNolo
        : secondSetQuestions;
    const questionsLeft =
      secondSetData.practiceAreas[language][
        conversation.PracticeAreaId.toUpperCase()
      ]?.questions;
    console.log(
      secondSetData.practiceAreas[language][
        conversation.PracticeAreaId.toUpperCase()
      ]
    );
    console.log(secondSetQuestions.practiceAreas[language]);
    const input = questionsLeft[0];
    newBotMessage.body = input.prompt;
    newBotMessage.buttons = input.options;
    newBotMessage.inputType = 'Input';
    newBotMessage.inputId = input.questionId;
    // questionsLeft.shift();
    const newInputs = [...questionsLeft];
    update(conversationRef, {
      QuestionsLeft: newInputs
    });
  } else if (
    conversation?.InputFieldsLeft === undefined &&
    conversation?.CurrentQuestionSet === 'initial-questions'
  ) {
    if (lawftyData.CaseTypeMap[text.toUpperCase()] && text !== 'OTHER') {
      update(conversationRef, {
        CaseType: lawftyData.CaseTypeMap[text.toUpperCase()]
      });
    }
    //if none of above -- then were at the info grab part of the convo.
    setInputToInvisible();
    const inputFields = [...inputFieldsSequence];
    const input = inputFields[0];
    newBotMessage.input = input;
    if (input === 'PhoneNumber') {
      newBotMessage.body = lawftyData?.[language][text][0].prompt;
    } else if (input === 'Info') {
      newBotMessage.input = 'Input';
      newBotMessage.inputType = 'text';
      newBotMessage.body =
        lawftyData?.[language][conversation.PracticeAreaId][1]?.prompt;
      newBotMessage.inputId = 'AdditionalDetails';
    } else {
      newBotMessage.body = lawftyData?.[input][language];
    }
    update(conversationRef, {
      CurrentQuestionSet: 'input-fields',
      InputFieldsLeft: inputFields,
      PracticeAreaId: text
    });
  } else if (Object.values(conversation?.InputFieldsLeft).length > 2) {
    //continue info grab
    const inputFields = Object.values(conversation.InputFieldsLeft);
    inputFields.shift();
    let input = inputFields[0];
    newBotMessage.input = input;
    if (input === 'PhoneNumber') {
      newBotMessage.body = lawftyData?.OtherSequence[language][1].prompt;
    } else if (input === 'Info') {
      const questionsLeft =
        secondSetQuestions.practiceAreas[language][
          conversation.PracticeAreaId.toUpperCase()
        ]?.questions;
      if (questionsLeft) {
        const input = questionsLeft[0];
        newBotMessage.body = input.prompt;
        newBotMessage.buttons = input.options;
        newBotMessage.inputType = 'Input';
        newBotMessage.inputId = input.questionId;
      } else {
        newBotMessage.input = 'Input';
        newBotMessage.inputType = 'text';
        newBotMessage.body =
          lawftyData?.[language][conversation.PracticeAreaId][1]?.prompt;
        newBotMessage.inputId = 'AdditionalDetails';
      }
      inputFields[0] = 'Details';
      inputFields.unshift('Info');
    } else if (input === 'Details') {
      if (text.length < 250) {
        newBotMessage.input = 'Input';
        newBotMessage.inputType = 'text';
        newBotMessage.body = lawftyData?.LawftySequence[language][0].prompt;
      } else {
        input = 'Name';
        newBotMessage.body = lawftyData?.Name[language];
      }
    } else {
      newBotMessage.body = lawftyData?.[input][language];
    }
    const newInputs = [...inputFields];
    update(conversationRef, {
      InputFieldsLeft: newInputs
    });
  } else if (
    conversation?.CurrentQuestionSet === 'input-fields' &&
    conversation?.LawftyCase === false
  ) {
    //ask last question for lawyer.com
    newBotMessage.body = lawftyData.LastLawyerPrompt[language];
    newBotMessage.input = 'Input';
    newBotMessage.inputType = 'text';
    setInputToVisible();
    update(conversationRef, {
      CurrentQuestionSet: 'final-questions'
    });
  } else {
    //end of the convo.
    if (!conversation.LawftyCase) {
      update(conversationRef, {
        SendToNolo: true
      });
    }

    newBotMessage.body = lawftyData.ClosingMessage[language];

    setInputToInvisible();
    closeChat(
      conversation,
      conversationRef,
      user,
      userRef,
      lawftyData.ClosingMessage[language]
    );
  }

  return sendMessage(
    messages,
    newBotMessage as AllMessages,
    setInputToInvisible,
    setNewPlaceholderText,
    messagesRef,
    messageLength
  );
};
export default getNextMessage;
