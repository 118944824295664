import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertProps } from '../types';

const InvalidAlert = ({ msg }: AlertProps) => {
  return (
    <>
      {msg && (
        <div className="block text-alert bg-alert-bg box-border rounded-xl border border-solid border-alert text-[12px] p-1.25 mb-1.25">
          <span className="m-[1px] text-alert p-1.25">
            <FontAwesomeIcon icon="times-circle" />
          </span>
          <span className="m-[1px] pl-[1%] text-alert">{msg}</span>
        </div>
      )}
    </>
  );
};

export default InvalidAlert;
