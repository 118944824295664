import { useRef, useState, memo, useEffect } from 'react';
import Message from './Message';
import { useUserContext } from 'context/userContext';
import { AllMessages, MessageListProps } from 'types';
import './Message.css';

const MessageList = memo(({ uuid, brand }: MessageListProps) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const userContext = useUserContext();
  const messageLength = userContext.messages
    ? Object.entries(userContext.messages as AllMessages).length
    : 0;
  const [inputState, setInputState] = useState('');
  useEffect(() => {
    document.querySelector('.MessageList')?.scrollTo({
      top: document.querySelector('.MessageList')?.scrollHeight!,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      listRef?.current?.scrollTo({
        top: document.querySelector('.MessageList')?.scrollHeight! + 100,
        left: 0,
        behavior: 'smooth'
      });
    }, 3500);
  }, [userContext.messages]);

  document
    .querySelector('.MessageList')
    ?.scroll(0, document.querySelector('.MessageList')?.scrollHeight!);

  setTimeout(
    () =>
      listRef?.current?.scrollTo({
        top: document.querySelector('.MessageList')?.scrollHeight! + 100,
        left: 0,
        behavior: 'smooth'
      }),
    1500
  );

  return (
    <div
      className="MessageList overflow-y-scroll flex-grow box-border pl-2.5 pr-2.5 pt-2.5 pb-12 mb-8"
      ref={listRef}
      data-testid="message-list"
    >
      <div className="connectionMessage">
        <p>Connecting...</p>
        <p>Patricia has joined the conversation!</p>
      </div>
      {userContext.messages
        ? Object.values(userContext.messages).map((message, idx) => (
            <Message
              key={idx}
              idx={idx}
              message={message as unknown as AllMessages}
              uuid={uuid}
              messageLength={messageLength}
              inputState={inputState}
              setInputState={setInputState}
              brand={brand}
            />
          ))
        : null}
    </div>
  );
});

export default MessageList;
