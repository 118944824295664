import { useEffect, useState, useCallback } from 'react';
// @ts-ignore
import shortId from 'shortid';
import { useRefConsumer, useRefHelper } from './useRefConsumer';

import type { Conversation, AllMessages, User } from '../types';

export const useInput = () => {
  const [inputInvisible, setInputInvisible] = useState(false);
  const [inputFieldsLeft, setInputFieldsLeft] = useState<any>();

  const setInputToInvisible = () => setInputInvisible(true);
  const setInputToVisible = () => setInputInvisible(false);

  return {
    inputInvisible,
    inputFieldsLeft,
    setInputFieldsLeft,
    setInputToInvisible,
    setInputToVisible
  };
};

export const useMessage = (uuid: string) => {
  const { getSnapshotByRef } = useRefConsumer();
  const { dbReferences } = useRefHelper(uuid);
  const [conversationRef, messagesRef, userRef] = dbReferences;
  const [conversation, setConversation] = useState<Conversation>();
  const [messages, setMessages] = useState<AllMessages>();
  const [user, setUser] = useState<User>();

  const restoredMessages = useCallback(async () => {
    const userRes = await getSnapshotByRef(userRef);
    const userSnapshot = userRes.toJSON() as User;
    const conversationRes = await getSnapshotByRef(conversationRef);
    const conversationSnapshot = conversationRes.toJSON() as Conversation;
    const messageRes = await getSnapshotByRef(messagesRef);
    const messagesSnapshot = messageRes.toJSON() as AllMessages;

    setNewUser(userSnapshot);
    setNewConversation(conversationSnapshot);

    setMessages(messagesSnapshot);
  }, []);

  useEffect(() => {
    restoredMessages();
  }, []);

  const resetMessages = () => {
    return setMessages(undefined);
  };

  const setNewConversation = (conversation: Conversation) => {
    setConversation(conversation);
  };

  const setNewMessages = (message: AllMessages) => {
    setMessages(message);
  };

  const setNewUser = (user: User) => {
    setUser(user);
  };

  return {
    messages,
    resetMessages,
    setNewMessages,
    user,
    setNewUser,
    conversation,
    setNewConversation,
    restoredMessages
  };
};

export const usePlaceholder = () => {
  const [placeholderText, setPlaceholderText] = useState('');

  const setNewPlaceholderText = (placeholder: string) =>
    setPlaceholderText(placeholder);

  return { placeholderText, setNewPlaceholderText };
};

export const useUuid = () => {
  const [uuid] = useState<string>(
    sessionStorage.getItem('uuid')
      ? (sessionStorage.getItem('uuid') as string)
      : shortId.generate()
  );

  sessionStorage.setItem('uuid', uuid);

  return { uuid };
};
