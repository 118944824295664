import { update } from 'firebase/database';
import { useRefConsumer, useRefHelper } from '../hooks/useRefConsumer';
import { MouseEvent } from 'react';
import getNextMessage from 'helpers/getNextMessage';
import { useInput, usePlaceholder } from 'hooks/useStateReducer';
import { useUserContext } from 'context/userContext';
import type { AllMessages, ButtonProps, Conversation, User } from '../types';

const Button = ({
  body,
  answerId,
  disabled,
  secondSetQuestionId,
  setDisabled,
  brand,
  uuid
}: ButtonProps) => {
  const { dbReferences } = useRefHelper(uuid);
  const { getSnapshotByRef } = useRefConsumer();
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { setNewPlaceholderText } = usePlaceholder();
  const { messages, conversation, user } = useUserContext();
  const [conversationRef] = dbReferences;

  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();

    if (secondSetQuestionId) {
      const conversationSnapshot = await getSnapshotByRef(conversationRef);
      const noloResponses = conversationSnapshot.val() || {};
      noloResponses[secondSetQuestionId - 1] = answerId;
      update(conversationRef, { NoloResponses: noloResponses });
    }

    const target = e.target as HTMLButtonElement;
    target.click();
    const targetVal = target.innerText;
    setDisabled(true);
    const messageLength = Object.entries(messages as AllMessages).length;
    return getNextMessage(
      {
        author: 'User',
        me: false,
        body: targetVal
      } as AllMessages,
      dbReferences,
      setInputToInvisible,
      setInputToVisible,
      setNewPlaceholderText,
      targetVal,
      messageLength,
      conversation as Conversation,
      user as User
    );
  };

  return (
    <>
      <button
        className={`rounded-2xl m-1.25 py-1.25 px-2.5 text-white font-bold inline-block border-none leading-normal ${
          disabled
            ? body === 'NO'
              ? 'bg-disabled-no-btn'
              : `${brand === 'd2c' ? 'bg-disabled-d2c' : 'bg-disabled'}`
            : body === 'NO'
            ? 'bg-enabled-no-btn'
            : `${brand === 'd2c' ? 'bg-enabled-d2c' : 'bg-enabled'}`
        }`}
        disabled={disabled}
        role='button'
        onClick={(e) => handleClick(e)}
      >
        {body}
      </button>
    </>
  );
};

export default Button;
