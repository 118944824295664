import { useIdleTimer } from 'react-idle-timer';
import { useInput, usePlaceholder } from './useStateReducer';
import { useRefHelper, useRefConsumer } from './useRefConsumer';
import type {
  BotMessages,
  UndefinedType,
  AllMessages,
  Button,
  User
} from '../types';
import sendMessage from '../helpers/sendMessage';
import lawftyData from 'lawfty_messages.json';
import { update } from 'firebase/database';
import { sendApiPost } from 'helpers/message_helper';

const useCloseChatIdleTimer = async (
  messages: AllMessages,
  language: 'english' | 'spanish',
  uuid: string
) => {
  const { setInputToVisible } = useInput();
  const { setNewPlaceholderText } = usePlaceholder();
  const { dbReferences } = useRefHelper(uuid);
  const [conversationRef, messagesRef, userRef] = dbReferences;
  const { getSnapshotByRef } = useRefConsumer();

  const onIdle = async () => {
    const chat = getSnapshotByRef(conversationRef).then((snapshot) =>
      snapshot.val()
    );
    const conversation = await chat;
    const user = await getSnapshotByRef(userRef).then((snapshot) =>
      snapshot.val()
    );

    if (uuid && !conversation?.ChatOver) {
      const newMessage = lawftyData['ChatTimeout'][language];

      const resetMessage: UndefinedType<AllMessages> = {
        author: 'Patricia',
        me: false,
        body: newMessage,
        buttons: [] as Button[],
        options: [] as string[],
        secondSetQuestionId: 0,
        disabled: false,
        language: language,
        input: '',
        brand: '',
        inputType: '',
        inputId: ''
      };
      sendMessage(
        conversation?.messages as AllMessages,
        resetMessage as BotMessages,
        setInputToVisible,
        setNewPlaceholderText,
        messagesRef,
        Object.entries(conversation?.messages as AllMessages).length
      );
      setInputToVisible();
      update(conversationRef, { ChatOver: true });
      setTimeout(() => {
        if (conversation?.messages) {
          sendApiPost(
            conversation,
            conversationRef,
            user as User,
            conversation.messages
          );
        }
      }, 3000);
    }
  };

  return useIdleTimer({
    element: document,
    onIdle: onIdle,
    debounce: 250,
    timeout: 1000 * 60 * 15
  });
};

export default useCloseChatIdleTimer;
