import { DatabaseReference, update } from 'firebase/database';
import { prodSetup } from '../constants';
import type { AllMessages, Conversation, User } from 'types';

export const changeTimezone = (date: Date, ianatz: string) => {
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz
    })
  );
  const diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() + diff);
};

export const closeChat = (
  conversation: Conversation,
  conversationRef: DatabaseReference,
  user: User,
  userRef: DatabaseReference,
  messages: AllMessages
) => {
  if (!conversation.ChatOver) {
    // update(conversationRef, { ChatOver: 'true' });
    setTimeout(() => {
      if (messages) {
        sendApiPost(conversation, conversationRef, user, messages);
      }
    }, 3000);
  }
};

export const formatConversation = (
  messages?: AllMessages | Conversation | User
) => {
  // @ts-expect-error
  if (messages.Brand && messages.Brand === 'd') {
    // @ts-expect-error
    messages.Brand = 'd2c';
  }
  if (messages) {
    // @ts-expect-error
    if (messages.conversation) {
      // @ts-expect-error
      delete messages.conversation;
    }
    // @ts-expect-error
    if (messages.messages) {
      delete Object.assign(messages, {
        Conversation: Object.entries(
          // @ts-expect-error
          messages.messages
        )
          // @ts-expect-error
          .map((msg) => `${msg[1]?.author}: ${msg[1]?.body}`)
          .join('\n')
        // @ts-expect-error
      })[messages];
      //@ts-expect-error
      delete messages.messages;
    }
    return messages;
  } else {
    return '';
  }
};

export const sendApiPost = (
  conversation: Conversation,
  conversationRef: DatabaseReference,
  user: User,
  messages: AllMessages
) => {
  update(conversationRef, { ChatOver: true });

  try {
    if (
      user['Phone'] !== null &&
      user['Phone'] !== undefined &&
      user['Phone']
    ) {
      if (user['FirstName'] === undefined || user['FirstName'] === null) {
        user['FirstName'] = 'Unknown';
      }
      if (prodSetup.prodURL) {
        try {
          fetch(
            prodSetup.prodURL ||
              'https://webhook.site/05691e88-e389-4297-ba2a-15a52084493c',
            {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              mode: 'cors',
              body: JSON.stringify({
                ...formatConversation(user),
                ...formatConversation(conversation),
                PostSource: 'client'
              })
            }
          ).then((res) => {
            console.log({ res });
          });
        } catch (e) {
          console.log({ e });
        }
        update(conversationRef, {
          PostSent: true,
          PostSource: 'client'
        });
      }
    }
  } catch (e) {
    throw new Error(`Error sending api post: ${e}`);
  }
};
