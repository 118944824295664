import { useEffect, useState } from 'react';
import { DropdownButton } from 'react-bootstrap';

import { MessageProps } from '../../../types';
import ButtonGroup from '../../ButtonGroup';
import Email from '../../Email';
import Name from '../../Name';
import SelectButton from '../../SelectButton';
import PhoneNumber from '../../PhoneNumber';
import ZipCode from '../../ZipCode';

// We should have one input component and not one for every input field
import { useRefHelper } from 'hooks/useRefConsumer';
import MessageForm from '../MessageForm';
import './Message.css';

const Message = ({
  idx,
  message,
  uuid,
  messageLength,
  inputState,
  setInputState,
  brand
}: MessageProps) => {
  idx === messageLength - 1
    ? (message.disabled = false)
    : (message.disabled = true);
  const {
    author,
    body,
    buttons,
    options,
    disabled,
    secondSetQuestionId,
    language,
    input,
    inputType,
    inputId
  } = message;
  // const messageList = useRef<ReactDOM.Container>(null);
  const [isDisabled, setDisabled] = useState(disabled || false);
  const [text, setText] = useState('Pick an option');
  const [loading, setLoading] = useState<boolean>(true);
  const { dbReferences } = useRefHelper(uuid);

  if (author !== 'User' && idx === messageLength - 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setInputState(input);
        setLoading(false);
      }, 3000);
    }, []);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setInputState(input);
      setLoading(false);
    }, []);
  }

  const dropdownSelectButtonClick = (variant: string) => {
    setText(variant);
    setDisabled(true);
  };

  const createDropdown = (options_list: string[]) => {
    return (
      options_list && (
        <DropdownButton
          className="max-h-7 rounded m-1.25 py-1.25 px-2.5 text-white font-bold block border-0 leading-6 bg-enabled"
          id="dropdown-basic-button"
          title={text}
          rootCloseEvent="click"
          disabled={isDisabled}
        >
          {options_list.map((variant: any) => {
            return (
              <SelectButton
                styles="bg-white text-black rounded-[1px] leading-6 block p-0.75"
                secondSetQuestionId={secondSetQuestionId}
                key={variant.answer_text}
                body={variant.answer_text}
                uuid={uuid}
                answerId={variant.answer}
                onClick={dropdownSelectButtonClick}
              />
            );
          })}
        </DropdownButton>
      )
    );
  };

  const createInput = (input: string) => {
    if (input) {
      return (
        <>
          {input === 'ZipCode' && (
            <ZipCode
              language={language}
              disabled={isDisabled}
              uuid={uuid}
              messageLength={messageLength}
            />
          )}
          {input === 'PhoneNumber' && (
            <PhoneNumber
              language={language}
              disabled={isDisabled}
              uuid={uuid}
              messageLength={messageLength}
            />
          )}
          {input === 'Email' && (
            <Email
              language={language}
              disabled={isDisabled}
              uuid={uuid}
              messageLength={messageLength}
            />
          )}
          {(input === 'Name' ||
            input === 'Details' ||
            message?.body?.length! > 249) && (
            <Name
              language={language}
              disabled={isDisabled}
              uuid={uuid}
              messageLength={messageLength}
            />
          )}
        </>
      );
    }
  };

  const createTextArea = (inputId: string, inputType: string) => {
    return (
      <>
        {inputId && inputType && (
          <MessageForm
            disabled={false}
            language={language}
            uuid={uuid}
            dbReferences={dbReferences}
          ></MessageForm>
        )}
      </>
    );
  };

  const authorId = `${author === 'User' ? 'MessageUser' : 'MessagePatricia'}`;

  // TODO: Fix ternary logic classname nightmare

  return loading ? (
    <div style={{ position: 'absolute', bottom: 0, zIndex: 0 }}>
      <p>Patricia is typing...</p>
    </div>
  ) : (
    <>
      <div id={authorId} className={`msg leading-6 mt-1`}>
        <div id="MessageContent">
          {author && (
            <p className={`font-bold mr-1 ${authorId}`}>
              {author === 'User' ? 'Me' : 'Patricia'}
            </p>
          )}
          <div
            className={`text-black leading-normal text-[13px] pt-[6px] pr-2.5 pb-[8px] pl-[12px] ${
              author
                ? author === 'User'
                  ? `${
                      brand === 'd2c' ? 'bg-msg-me-d2c' : 'bg-msg-me'
                    } rounded-b-2xl rounded-tl-2xl rounded-tr-none`
                  : `${
                      brand === 'd2c' ? 'bg-msg-bot-d2c' : 'bg-msg-bot'
                    } rounded-b-2xl rounded-tr-2xl rounded-tl-none`
                : `${
                    brand === 'd2c' ? 'bg-d2c' : 'bg-white'
                  } text-center text-black p-0`
            }`}
            style={{ whiteSpace: 'pre-line' }}
          >
            <>
              {body}
              <div>{createInput(input)}</div>
              <div>
                {buttons && (
                  <ButtonGroup
                    message={message}
                    messageLength={messageLength}
                    buttons={buttons}
                    secondSetQuestionId={secondSetQuestionId}
                    disabled={isDisabled}
                    uuid={uuid}
                    brand={brand}
                  />
                )}
              </div>
              <div>{createDropdown(options)}</div>
            </>
          </div>
        </div>
      </div>
      {inputState === 'Input' ? (
        <>{createTextArea(inputId as string, inputType as string)}</>
      ) : (
        <></>
      )}
    </>
  );
};

export default Message;
