import Button from './Button';
import { ButtonGroupProps } from '../types';
import { useState } from 'react';
// import useNextMessage from '../hooks/useNextMessage';

const ButtonGroup = ({
  message,
  buttons,
  disabled,
  secondSetQuestionId,
  uuid,
  brand
}: ButtonGroupProps) => {
  const [isDisabled, setDisabled] = useState(disabled);

  return (
    <>
      {buttons
        ? Object.values(buttons).map((btn, i) => {
            return (
              <Button
                key={btn.answer_text}
                message={message}
                answerId={btn.answer}
                secondSetQuestionId={secondSetQuestionId}
                body={btn.answer_text}
                disabled={isDisabled}
                setDisabled={setDisabled}
                uuid={uuid}
                brand={brand}
                onClick={() => {}}
              />
            );
          })
        : ''}
    </>
  );
};

export default ButtonGroup;
