import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from 'lawfty_messages.json';
import { useInput, usePlaceholder } from 'hooks/useStateReducer';
import { useUserContext } from 'context/userContext';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { update } from 'firebase/database';
import { useRefHelper } from 'hooks/useRefConsumer';
import getNextMessage from 'helpers/getNextMessage';
import type { AllMessages, Conversation, EmailProps, User } from '../types';

const Email = ({ language, disabled, uuid, messageLength }: EmailProps) => {
  const [isDisabled, setDisabled] = useState<boolean>(disabled || false);
  const [email, setEmail] = useState<string>('');
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [style] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { conversation, user } = useUserContext();
  const { setNewPlaceholderText } = usePlaceholder();
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { dbReferences } = useRefHelper(uuid);
  const [, , userRef] = dbReferences;

  const handleChange = (e: ChangeEvent<HTMLInputElement>, email: string) => {
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    setEmail(e.target.value);
    if (isEmail) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isDisabled) {
      setDisabled(true);
      const form = e.target as HTMLFormElement;
      const input = form?.firstChild as HTMLInputElement;
      const value = input.value;
      setDisabled(true);
      update(userRef, { Email: value });
      return getNextMessage(
        {
          author: 'User',
          me: false,
          body: value
        } as AllMessages,
        dbReferences,
        setInputToInvisible,
        setInputToVisible,
        setNewPlaceholderText,
        value,
        messageLength,
        conversation as Conversation,
        user as User
      );
    }
  };

  if (!isDisabled) inputRef?.current?.focus();

  return (
    <div>
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        style={{ display: 'inline-flex', width: '100%' }}
      >
        <input
          className={`${style} border-0 py-1.25 px-0.75 focus:bg-email-input w-full`}
          id="email-input"
          ref={inputRef}
          type="email"
          placeholder={lawftyData.EmailPlaceholder[language]}
          disabled={isDisabled}
          value={email}
          style={
            validEmail
              ? { borderBottom: '2px solid green', backgroundColor: '#f2f8f2' }
              : {}
          }
          onChange={(e) => handleChange(e, email)}
        />
        <button
          className="bg-send border-0 text-white font-bold h-send w-send"
          id="email-send-button"
          type="submit"
          disabled={!email}
        >
          <FontAwesomeIcon id="arrow-right" icon="arrow-right" />
        </button>
      </form>
    </div>
  );
};

export default Email;
