import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import InvalidAlert from './InvalidAlert';
import { FormEvent, useEffect, useRef, useState } from 'react';
import lawftyData from 'lawfty_messages.json';
import { E164Number } from 'libphonenumber-js/types';
import { update } from 'firebase/database';
import { useRefHelper } from 'hooks/useRefConsumer';
import getNextMessage from 'helpers/getNextMessage';
import { useInput, usePlaceholder } from 'hooks/useStateReducer';
import type { AllMessages, Conversation, PhoneProps, User } from '../types';
import { useUserContext } from 'context/userContext';

const PhoneNumber = ({
  language,
  disabled,
  uuid,
  messageLength
}: PhoneProps) => {
  const [value, setValue] = useState<E164Number | undefined>();
  const [isDisabled, setDisabled] = useState(disabled);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [invalidMsg, setInvalidMsg] = useState('');
  const [style, setStyle] = useState('');
  const inputRef = useRef<any>();
  const { conversation, user } = useUserContext();
  const { setNewPlaceholderText } = usePlaceholder();
  const { setInputToInvisible, setInputToVisible } = useInput();
  const { dbReferences } = useRefHelper(uuid);
  const [, , userRef] = dbReferences;

  useEffect(() => {
    if (!isDisabled) inputRef.current?.focus();
    if (buttonDisabled && value) setButtonDisabled(false);
    if (!value || isDisabled) {
      setStyle('phone-number-no-entry');
    } else if (value.length === 12) {
      setStyle('phone-number-valid');
    } else {
      setStyle('phone-number-invalid');
    }
  }, [buttonDisabled, isDisabled, value]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && style === 'phone-number-valid' && !isDisabled) {
      const val = e.target.value;
      setDisabled(true);
      setInvalidMsg('');
      update(userRef, { Phone: val });
      return getNextMessage(
        {
          author: 'User',
          me: false,
          body: val
        } as AllMessages,
        dbReferences,
        setInputToInvisible,
        setInputToVisible,
        setNewPlaceholderText,
        val,
        messageLength,
        conversation as Conversation,
        user as User
      );
    } else if (e.key === 'Enter' && style === 'phone-number-invalid') {
      setInvalidMsg(lawftyData.PhoneNumberInvalidAlert[language || 'english']);
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isDisabled) {
      if (style === 'phone-number-valid') {
        setDisabled(true);
        setInvalidMsg('');
        update(userRef, { Phone: value });
        return getNextMessage(
          {
            author: 'User',
            me: false,
            body: value
          } as AllMessages,
          dbReferences,
          setInputToInvisible,
          setInputToVisible,
          setNewPlaceholderText,
          value as E164Number,
          messageLength,
          conversation as Conversation,
          user as User
        );
      } else if (style === 'phone-number-invalid') {
        setInvalidMsg(
          lawftyData.PhoneNumberInvalidAlert[language || 'english']
        );
      }
    }
  };

  return (
    <div>
      <InvalidAlert msg={invalidMsg} />
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        style={{ display: 'inline-flex', width: '100%' }}
      >
        <PhoneInput
          className={`w-full pl-1 border-none focus:border-none focus:outline-none focus:bg-email-input touch-none ${style}`}
          id="phone-number-input"
          ref={inputRef}
          placeholder={lawftyData.PhoneNumberPlaceholder[language || 'english']}
          country="US"
          defaultCountry="US"
          value={value || ''}
          onChange={(value) => setValue(value)}
          onKeyPress={handleKeyDown}
          disabled={isDisabled}
        />
        <button
          className={`bg-send border-none text-white font-bold h-send w-send ${style}`}
          id="phone-send-button"
          type="submit"
          onClick={handleFormSubmit}
          disabled={buttonDisabled}
        >
          <FontAwesomeIcon id="arrow-right" icon="arrow-right" />
        </button>
      </form>
    </div>
  );
};

export default PhoneNumber;
