import { Language } from '../types';
import lawftyData from 'lawfty_messages.json';

const Disclaimer = ({ language }: Language) => {
  return (
    <>
      <div
        className="absolute bottom-0 inline bg-black text-xxs outline-none h-[36px] w-full p-[2px]"
        data-testid="disclaimer"
      >
        <span className="flex basis-11/12 m-[1px] pl-[1%] text-white">
          {lawftyData.Disclaimer[language]}
        </span>
      </div>
    </>
  );
};

export default Disclaimer;
