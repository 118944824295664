import { LogoProps } from '../types';

const FirmLogo = ({ brand, campaignId }: LogoProps) => {
  const url =
    brand === 'd2c'
      ? 'https://s3-us-west-1.amazonaws.com/lawfty5-uploads/assets/Lawfty_Logo_Blue.png'
      : 'https://chatbot52p4q.s3.amazonaws.com/' + campaignId + '_small.png';
  return (
    <div
      className={`font-logo inline box-border text-[22px] px-2.5 outline-none ${
        brand === 'd2c' ? 'w-[unset] py-2.5' : 'px-1.25'
      }`}
    >
      <img
        src={url}
        alt="Firm Logo"
        className="w-2/5 max-w-[300px] max-h-24 min-w-[90px] min-h-send p-[10px]"
      />
    </div>
  );
};

export default FirmLogo;
