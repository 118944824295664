import queryString from 'query-string';
import lawftyData from '../lawfty_messages.json';

export enum AgentName {
  Patricia = 'Patricia'
}

const searchQuery =
  typeof window === 'object' ? queryString.parse(window.location.search) : {};

interface IAppInfo {
  inputFieldsSequence: [
    'PhoneNumber',
    'Info',
    'Name',
    'ZipCode',
    'Email',
    'Completed'
  ];
  brand: string;
  language: 'english' | 'spanish';
  campaignId: string;
  campaignType: string;
}

export const AppInfo: IAppInfo = {
  inputFieldsSequence: [
    'PhoneNumber',
    'Info',
    'Name',
    'ZipCode',
    'Email',
    'Completed'
  ],
  brand: (searchQuery?.brand as string) || '',
  language: (searchQuery?.language as IAppInfo['language']) || 'english',
  campaignId: (searchQuery?.firm as string) || '',
  campaignType: (searchQuery?.type as string) || 'PersonalInjury'
};

export const prodSetup = {
  prodURL:
    process.env['REACT_APP_API_URL'] ||
    'https://webhook.site/05691e88-e389-4297-ba2a-15a52084493c',
  brand: searchQuery?.brand ? searchQuery?.brand[0] : '',
  visitToken: searchQuery?.visit_token || 'none'
};

export const button_list =
  //@ts-ignore
  lawftyData.ButtonList[AppInfo.campaignType][AppInfo.language];

export const InitialMessages = [
  {
    author: AgentName.Patricia,
    me: false,
    body: lawftyData.WelcomeMessage[AppInfo.language].replace(
      '$$',
      AgentName.Patricia
    ),
    buttons: button_list
  }
];
